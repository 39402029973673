import React, { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoMdStar } from "react-icons/io";
import axios from "axios";
import "./blogEntrie.style.scss";
import { blogBackground, blogUrl, mobileBlogMobile } from "../home/fetch";

export default function BlogEntrie() {
	const [posts, setPosts] = useState([]);
	const [actualLink, setActualLink] = useState("");
	const [isPortrait, setIsPortrait] = useState(false);
	const [hambMenu, setHambMenu] = useState(false);

	useEffect(() => {
		const actualWidth = window.innerWidth;
		const actualHeight = window.innerHeight;

		actualWidth < actualHeight && setIsPortrait(true);

		document.documentElement.style.setProperty("--screen-height", `${actualHeight}px`);
		document.documentElement.style.setProperty("--screen-width", `${actualWidth}px`);

		const link = window.location.href;
		const splitParts = link.split("/");
		const slug = splitParts[splitParts.length - 2];
		setActualLink(slug);

		const fetchData = async () => {
			try {
				const apiUrl = `${blogUrl}/wp-json/wp/v2/posts?categories=1`;
				const response = await axios.get(apiUrl);
				const data = response.data;
				const postWithImages = await Promise.all(
					data.map(async (post) => {
						if (post.acf && post.acf.main_image) {
							try {
								const imageUrlResponse = await axios.get(
									`${blogUrl}/wp-json/wp/v2/media/${post.acf.main_image}`
								);
								const imageData = imageUrlResponse.data;
								const imageUrl = imageData.source_url;
								return { ...post, imageUrl };
							} catch (imageError) {
								console.error("Error fetching image data:", imageError);
								return { ...post, imageUrl: null };
							}
						}
						return post;
					})
				);
				setPosts(postWithImages);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};
		fetchData();
	}, []);

	const ActualLink = () => {
		return posts.map((post) => {
			if (post.slug === actualLink) {
				return (
					<div key={post.id} className="post-entrie-container">
						<div className="selected-post-container">
							<h2>{post.title.rendered}</h2>
							<div className="post-main-image-hidden-container">
								{post.imageUrl && <img src={post.imageUrl} alt="" />}
							</div>
							<div dangerouslySetInnerHTML={{ __html: post.acf.content }} />
						</div>
					</div>
				);
			}
			return null;
		});
	};

	const BlockEntriesAside = () => {
		return (
			<aside className="blog-aside">
				<h4>OTRAS ENTRADAS:</h4>
				{posts.map((post) => {
					if (post.slug !== actualLink) {
						return (
							<div
								className="posts-aside"
								onClick={() => {
									window.open(post.link);
								}}
								key={post.id}>
								<div className="img-aside-hidden">
									{post.imageUrl && <img src={post.imageUrl} alt="" />}
								</div>
								<p className="blog-aside-title">{post.title.rendered}</p>
							</div>
						);
					}
					return null;
				})}
			</aside>
		);
	};

	const HamburguerMenu = () => {
		return (
			<ul className="hamburguer-menu">
				<div className="close-button-div">
					<IoIosCloseCircleOutline
						size={40}
						onClick={() => {
							setHambMenu(false);
						}}
					/>
				</div>
				{posts.map((post) => {
					if (post.slug !== actualLink) {
						return (
							<div
								onClick={() => {
									window.open(post.link);
								}}
								key={post.id}>
								<li>
									<IoMdStar /> {post.title.rendered}
								</li>
							</div>
						);
					}
					return null;
				})}
			</ul>
		);
	};

	const Loop = () => {
		return (
			<div className="container">
				<p id="demo">Diseño, creación y desarrollo... &nbsp;&nbsp;</p>
				<p id="demo">Diseño, creación y desarrollo...&nbsp;&nbsp;&nbsp;</p>
				<p id="demo">Diseño, creación y desarrollo...&nbsp;&nbsp;&nbsp;</p>
			</div>
		);
	};

	return (
		<div
			className="blog-entries-main-container"
			style={{ backgroundImage: `url(${isPortrait ? mobileBlogMobile : blogBackground}` }}>
			<div className="blog-header">
				<h2>Ex Machina</h2>
				{!isPortrait && <Loop />}
				{isPortrait && (
					<div style={{ position: "relative" }}>
						<FiMenu size={60} onClick={() => setHambMenu(true)} />
						{hambMenu && <HamburguerMenu />}
					</div>
				)}
			</div>
			<div className="blog-content-entries-container">
				<ActualLink />
				<div className="blog-posts-aside-hidden">
					{!isPortrait && <BlockEntriesAside />}
				</div>
			</div>
		</div>
	);
}
