import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";

import "./blog.style.scss";
import { blogBackgroundImage, blogUrl, polaroidBackground } from "./fetch";

const Blog = () => {
	const [postData, setPostData] = useState([]);
	const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${blogUrl}/wp-json/wp/v2/posts?categories=1`);
				const data = await response.json();
				const postWithImages = await Promise.all(
					data.map(async (post) => {
						if (post.acf && post.acf.main_image) {
							const imageUrlResponse = await fetch(
								`${blogUrl}/wp-json/wp/v2/media/${post.acf.main_image}`
							);
							const imageData = await imageUrlResponse.json();
							const imageUrl = imageData.source_url;

							return { ...post, imageUrl };
						}

						return post;
					})
				);
				setPostData(postWithImages);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};
		fetchData();

		const handleResize = () => {
			setIsPortrait(window.innerHeight > window.innerWidth);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const title = "BLOG";

	const BlogThumbnail = () => {
		return (
			<>
				{postData.length > 0 ? (
					<div key={postData[0].id} className="blog-card">
						<p className="blog-title">{postData[0].title.rendered}</p>
						{postData[0].imageUrl && <img src={postData[0].imageUrl} alt="" />}
					</div>
				) : (
					<p style={{ fontFamily: '"six caps", sans-serif' }}>ESTOY LLEGANDO...</p>
				)}
			</>
		);
	};

	const FullBlogsList = () => {
		return (
			<>
				<div className="last-entrance-container">
					{postData.length > 0 ? (
						<div
							key={postData[0].id}
							onClick={() => window.open(postData[0].link)}
							className="last-blog-entrance"
							style={{ backgroundImage: `${polaroidBackground}` }}>
							{postData[0].imageUrl && (
								<img
									style={{
										width: isPortrait ? "76vw" : "18vw",
										height: isPortrait ? "79vw" : "20vw",
									}}
									src={postData[0].imageUrl}
									alt=""
								/>
							)}
							<div className="subtitle-and-button-container">
								<p className="blog-title-polaroid">{`001/ ${postData[0].title.rendered}`}</p>
							</div>
						</div>
					) : (
						<p style={{ fontFamily: '"six caps", sans-serif' }}>ESTOY LLEGANDO...</p>
					)}
				</div>
				<div className="old-blog-entrances">
					{postData.map((post, i) => {
						if (i !== 0 && i < 4) {
							return (
								<p
									key={post.id}
									onClick={() => window.open(post.link)}
									className="blog-title">
									{post.title.rendered} <FaStar size={20} />
								</p>
							);
						}
						return null;
					})}
					<p className="blog-title">VER MAS...&nbsp;&nbsp;&nbsp;</p>
				</div>
			</>
		);
	};

	return (
		<div className="blog-main-container" style={{ backgroundImage: `${blogBackgroundImage}` }}>
			{!isPortrait && <BlogThumbnail />}
			<FullBlogsList />
			<div
				className="blog-area-title"
				style={{
					bottom: "-6vh",
					left: "-0.2vw",
					fontSize: "25vh",
				}}>
				{title}
			</div>
			<div
				className="blog-area-title"
				style={{
					width: "100%",
					justifyContent: "end",
					paddingRight: "2vw",
					bottom: "-45vh",
					fontSize: "40vh",
				}}>
				{title}
			</div>
		</div>
	);
};

export default Blog;
