import { useEffect, useState } from "react";
import "./contact.style.scss";
import { contactBackground } from "./fetch";

export default function Contact() {
	const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
	useEffect(() => {
		const handleResize = () => {
			setIsPortrait(window.innerHeight > window.innerWidth);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	const title = "CONTACT";
	return (
		<div className="contact-main-container">
			<h2>¿Quieres saber más?</h2>
			<img
				className="contact-image"
				src={contactBackground}
				alt="cabina de teléfono de Inglaterra"
			/>
			<div className="contact-info-container">
				<button onClick={() => window.open("")} className="cv">
					Cv
				</button>
				<div className="division-line" />
				<div className="links-contacts-container">
					<button>fran@exmachina.es</button>
					<button>+34 666750331</button>
					<button onClick={()=>window.open("https://github.com/Frankenweenee")}>GitHub</button>
					<button onClick={()=>window.open("https://www.linkedin.com/in/franmartinezclavijo/")}>LinkedIn</button>
				</div>
			</div>
			{!isPortrait && (
				<div className="contact-area-title" style={{ bottom: "-2vh", left: "-0.2vw", fontSize : "7.5vh" }}>
					{title}
				</div>
			)}
			<div
				className="contact-area-title"
				style={
					isPortrait
						? {
								width: "100%",
								bottom: "-5.5vh",
								fontSize: "25vh",
								fontWeight: "bold",
						  }
						: {
								width: "100%",
								justifyContent: "end",
								paddingRight: "2vw",
								bottom: "-45vh",
								fontSize: "40vh",
								fontWeight: "bold",
						  }
				}>
				{title}
			</div>
		</div>
	);
}
