import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Main() {
	useEffect(() => {
		const screenWidth = window.innerWidth;
		const screenHeight = window.innerHeight;

		const homeMainContainer = document.querySelector(".home-main-container");
		const worksMainContainer = document.querySelector(".works-main-container");
		const worksLeft = worksMainContainer.getBoundingClientRect().left;
		const blogMainContainer = document.querySelector(".blog-main-container");
		const blogLeft = blogMainContainer.getBoundingClientRect().left;
		const contactMainContainer = document.querySelector(".contact-main-container");
		const contactLeft = contactMainContainer.getBoundingClientRect().left;
		const splitHomeTitles = document.querySelectorAll(".text-init");
		const worksAreaTitle = document.querySelectorAll(".works-area-title");
		const blogAreaTitle = document.querySelectorAll(".blog-area-title");
		const worksUpContainer = document.getElementById("works-up");
		const contactTitle = document.querySelectorAll(".contact-area-title");
		const contactLink = document.querySelectorAll(".contact-hidden");
		const mainWorksContainer = document.getElementById("big-works-up");
		const elementsToMove = [
			homeMainContainer,
			worksMainContainer,
			blogMainContainer,
			contactMainContainer,
		];

		const maxScrollDistance = [worksLeft, worksLeft, blogLeft, contactLeft];

		const deltaPos = [0, 0, 0, 0];

		let singleDeltaPos = 0;

		let responsiveValueHeight = (screenSize) => {
			return (screenHeight * screenSize) / 598;
		};

		let responsiveValueWidth = (screenSize) => {
			return (screenWidth * screenSize) / 1280;
		};

		const scrollingMainContainerLeft = (elementToMove, delta) => {
			gsap.to(elementToMove, {
				x: delta,
				ease: "power1.out",
			});
		};

		const scrollingMainContainerRight = (elementToMove, delta) => {
			gsap.to(elementToMove, {
				x: delta,
				ease: "power1.out",
			});
		};

		const wordsSplitToAnimate = (title = "") => {
			const titleArray = title.split("");
			const finalArray = titleArray.map((character) => {
				if (character === " ") {
					return `<span>&nbsp;</span>`;
				} else {
					return `<span>${character}</span>`;
				}
			});
			return finalArray.join("");
		};

		const dropLettersAnimation = (selector, stagger) => {
			const shuffledElements = Array.from(selector).sort(() => Math.random() - 0.5);
			gsap.from(shuffledElements, {
				opacity: 0,
				ease: "elastic.out(1, 0.75)",
				y: responsiveValueWidth(-500),
				delay: (index) => index * stagger,
			});
		};

		const scalateAndOpacityAnimation = (element, i) => {
			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: element[0],
					start: "top center",
					markers: true,
				},
			});

			gsap.fromTo(
				element,
				{ scale: 2, opacity: 0 },
				{
					scale: 1,
					opacity: 1,
					duration: 1,
					scrollTrigger: {
						start: "top 50%",
						end: "top 50%",
						trigger: element,
						markers: true,
						toggleActions: "play none none none",
					},
				}
			);
		};

		splitHomeTitles.forEach((titleElement) => {
			const newTextContent = wordsSplitToAnimate(titleElement.textContent);
			titleElement.innerHTML = newTextContent;
		});
		splitHomeTitles.forEach((title) => {
			const spans = title.querySelectorAll("span");
			dropLettersAnimation(spans, 0.12);
		});

		blogAreaTitle.forEach((singleElement) => {
			const blogTitleSpans = wordsSplitToAnimate(singleElement.textContent);
			singleElement.innerHTML = blogTitleSpans;
		});

		worksAreaTitle.forEach((singleElement) => {
			const worksTitleSpans = wordsSplitToAnimate(singleElement.textContent);
			singleElement.innerHTML = worksTitleSpans;
		});

		contactTitle.forEach((titleElement) => {
			const newTextContent = wordsSplitToAnimate(titleElement.textContent);
			titleElement.innerHTML = newTextContent;
		});

		contactLink.forEach((link) => {
			const splitLinks = wordsSplitToAnimate(link.textContent);
			link.innerHTML = splitLinks;
		});

		if (screenWidth > screenHeight) {
			window.addEventListener("wheel", function (e) {
				const mainWorksElements = mainWorksContainer.querySelectorAll(".post-card");
				const lastEntranceContainer = document.querySelectorAll(".last-entrance-container");
				const allBlogEntrances = document.querySelector(".old-blog-entrances");
				const singleCard = allBlogEntrances.querySelectorAll(".blog-title");
				const worksElements = worksUpContainer.querySelectorAll(".post-card");

				elementsToMove.forEach((element, i) => {
					let deltaSpeed = 0.5;
					if (e.deltaY < 0) {
						if (i === 0 || i === 1) {
							deltaPos[i] += e.deltaY * deltaSpeed;
							deltaPos[i] = Math.max(-maxScrollDistance[i], Math.min(deltaPos[i], 0));
							scrollingMainContainerLeft(element, deltaPos[i]);
						}
						if (i === 2) {
							if (
								worksMainContainer.getBoundingClientRect().right >= screenWidth &&
								deltaPos[i] > -1
							) {
								deltaPos[i] += e.deltaY * -0.5;
								deltaPos[i] = Math.max(
									0,
									Math.min(deltaPos[i], screenWidth - blogLeft)
								);
								scrollingMainContainerRight(element, deltaPos[i]);
							} else {
								deltaPos[i] += e.deltaY * deltaSpeed;
								deltaPos[i] = Math.max(
									-maxScrollDistance[i],
									Math.min(
										deltaPos[i],
										Math.round(
											-(
												blogLeft -
												worksMainContainer.getBoundingClientRect().right
											)
										)
									)
								);
								scrollingMainContainerLeft(element, deltaPos[i]);
							}
						}
						if (i === 3) {
							if (
								blogMainContainer.getBoundingClientRect().right >= screenWidth &&
								deltaPos[i] > -1
							) {
								deltaPos[i] += e.deltaY * -0.5;
								deltaPos[i] = Math.max(
									0,
									Math.min(deltaPos[i], screenWidth - contactLeft)
								);
								scrollingMainContainerRight(element, deltaPos[i]);
							} else {
								deltaPos[i] =
									blogMainContainer.getBoundingClientRect().left < screenWidth
										? Math.min(
												deltaPos[i],
												blogMainContainer.getBoundingClientRect().right
										  )
										: screenWidth;
								deltaPos[i] += e.deltaY * deltaSpeed;
								deltaPos[i] = Math.max(
									-maxScrollDistance[i],
									Math.min(
										deltaPos[i],
										-(
											contactLeft -
											Math.round(
												blogMainContainer.getBoundingClientRect().right
											)
										)
									)
								);
								scrollingMainContainerLeft(element, deltaPos[i]);
							}
						}
					}
					if (e.deltaY > 0) {
						if (i === 3) {
							deltaPos[i] += e.deltaY * deltaSpeed;
							deltaPos[i] = Math.min(deltaPos[i], 0);
							scrollingMainContainerLeft(element, deltaPos[i]);
						}
						if (i === 2) {
							if (
								contactMainContainer.getBoundingClientRect().left >
									blogMainContainer.getBoundingClientRect().right ||
								blogMainContainer.getBoundingClientRect().right > contactLeft
							) {
								deltaPos[i] += e.deltaY * deltaSpeed;
								deltaPos[i] = Math.max(
									-(blogLeft - blogMainContainer.getBoundingClientRect().left),
									Math.min(deltaPos[i], 0)
								);
								scrollingMainContainerLeft(element, deltaPos[i]);
							}
						}
						if (i === 0 || i === 1) {
							if (
								blogMainContainer.getBoundingClientRect().left >
									worksMainContainer.getBoundingClientRect().right ||
								worksMainContainer.getBoundingClientRect().right > blogLeft
							) {
								deltaPos[i] += e.deltaY * deltaSpeed;
								deltaPos[i] = Math.max(
									-(worksLeft - worksMainContainer.getBoundingClientRect().left),
									Math.min(deltaPos[i], 0)
								);
								scrollingMainContainerLeft(element, deltaPos[i]);
							}
						}
					}
				});

				const titleMovementInY = (
					elementContainer,
					elementTrigger,
					triggerRight,
					minScrollDistance,
					maxScrollDistance,
					delayDistance
				) => {
					elementContainer.forEach((singleElement, i) => {
						if (e.deltaY < 0) {
							let delay = i * delayDistance;
							if (
								singleElement.getBoundingClientRect().left + delay <
								elementTrigger - 5
							) {
								singleDeltaPos += e.deltaY * 0.7;
								gsap.to(singleElement, {
									y: Math.max(
										responsiveValueHeight(minScrollDistance),
										Math.min(
											singleDeltaPos,
											responsiveValueHeight(maxScrollDistance)
										)
									),
									ease: "power1.out",
								});
							}
						}
						if (
							e.deltaY > 0 &&
							triggerRight - 10 < singleElement.getBoundingClientRect().left
						) {
							gsap.to(singleElement, {
								y: 0,
								ease: "power1.out",
							});
						}
					});
				};

				/*const contactLinkScroll = (elementToScroll) => {
				gsap.to(elementToScroll, {
					y: 0,
					ease: "power1.out",
				});
			};*/

				titleMovementInY(worksElements, worksLeft, worksLeft + 1, -500, 0, 50);

				titleMovementInY(
					mainWorksElements,
					responsiveValueWidth(600),
					responsiveValueWidth(200),
					-600,
					0,
					-90
				);
				worksAreaTitle.forEach((element, i) => {
					const singleElement = element.querySelectorAll("span");
					i === 0
						? titleMovementInY(singleElement, worksLeft, worksLeft, 140, 0, 2)
						: titleMovementInY(singleElement, worksLeft, worksLeft, -250, 0, 2);
				});

				blogAreaTitle.forEach((element, i) => {
					const singleElement = element.querySelectorAll("span");
					i === 0
						? titleMovementInY(singleElement, blogLeft, worksLeft, 140, 0, 2)
						: titleMovementInY(
								singleElement,
								responsiveValueWidth(1100),
								worksLeft,
								-250,
								0,
								2
						  );
				});

				titleMovementInY(
					document.querySelectorAll(".blog-card"),
					blogLeft,
					responsiveValueWidth(700),
					-460,
					0,
					100
				);

				blogAreaTitle.forEach((element, i) => {
					const singleElement = element.querySelectorAll("span");
					i === 0
						? titleMovementInY(singleElement, blogLeft, blogLeft + 180, 140, 0, 2)
						: titleMovementInY(
								singleElement,
								responsiveValueWidth(1200),
								worksLeft,
								-250,
								0,
								2
						  );
				});

				titleMovementInY(
					lastEntranceContainer,
					responsiveValueWidth(870),
					responsiveValueWidth(400),
					619,
					0,
					2
				);

				titleMovementInY(
					singleCard,
					responsiveValueWidth(700),
					responsiveValueWidth(500),
					-450,
					0,
					50
				);

				contactTitle.forEach((element, i) => {
					const singleElement = element.querySelectorAll("span");
					i === 0
						? titleMovementInY(
								singleElement,
								responsiveValueWidth(1100),
								contactLeft - 10,
								140,
								0,
								2
						  )
						: titleMovementInY(
								singleElement,
								responsiveValueWidth(1250),
								responsiveValueWidth(1201),
								-250,
								0,
								2
						  );
				});

				return () => {
					window.removeEventListener("wheel");
				};
			});
		} else {
			window.addEventListener("load", function () {
				const mainWorksElements = mainWorksContainer.querySelectorAll(".post-card");
				mainWorksElements.forEach((element) => {
					gsap.fromTo(
						element,
						{ scale: 5, opacity: 0 },
						{
							scale: 1,
							opacity: 1,
							duration: 0.5,
							ease: "power1.out",
							scrollTrigger: {
								trigger: element,
								start: "top 30%",
								markers: true,
								toggleActions: "play none none none",
							},
						}
					);
				});
				ScrollTrigger.refresh();
			});
		}
	}, []);
}
