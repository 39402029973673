import { useEffect, useState } from "react";

//import "./home.style.scss";
//import "./web-page.style.scss";

import Home from "./home";
import Works from "./works";
import Blog from "./blog";
import Contact from "./contact";
import Main from "./js/main";
import { fullBackBackground } from "./fetch";
//import Rule from "./rule";

export default function Home_react() {
	const [screenDirection, setScreenDirection] = useState([]);
	useEffect(() => {
		const screenHeight = window.innerHeight;
		const screenWidth = window.innerWidth;

		document.documentElement.style.setProperty("--screen-height", `${screenHeight}px`);
		document.documentElement.style.setProperty("--screen-width", `${screenWidth * 0.7}px`);
		document.documentElement.style.setProperty("--screen-width-portrait", `${screenWidth}px`);
		document.documentElement.style.setProperty("--screen-full-width", `${screenWidth}px`);


		let portraitScreen = {
			display: "flex",
			flexDirection: "column",
			position: "static",
			width: "100%",
			overflow: "visible",
			backgroundColor: "#c3c9cb",
			backgroundRepeat : "no-repeat",
			backgroundSize: "cover"

		};

		let landscapeSreen = {
			display: "flex",
			flexDirection: "column",
			position: "fixed",
			width: "100%",
			overflow: "hidden",
			backgroundColor: "#c3c9cb",
			backgroundImage : `${fullBackBackground}`,
			backgroundRepeat : "no-repeat",
			backgroundSize: "cover"

		};

		setScreenDirection(screenWidth > screenHeight ? landscapeSreen : portraitScreen);
	}, []);
	return (
		<>
	  	<div className="scroll-container" style={screenDirection}>
				{/* <Rule /> */}
				<Home />
				<Works />
				<Blog />
				<Contact />
				<Main />
			</div>
		</>
	);
}
