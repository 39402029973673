import React, { useEffect, useState } from "react";
import "./home.style.scss";
import "./js/wordsSplitToAnimate";
import { homeBackgroungImage } from "./fetch";

export default function Home() {

	const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
	useEffect(() => {
		const handleResize = () => {
			setIsPortrait(window.innerHeight > window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	const attributes = {
		companyName: "EX MACHINA",
		first_title: "DISEÑO",
		second_title: "DESARROLLO WEB",
	};

	return (
		<div className="home-main-container" style={{ backgroundImage: `${homeBackgroungImage}`}}>
			<div className="text-init">{attributes.first_title}</div>
			<div className="text-init">{attributes.second_title}</div>
			<div className="init-line"></div>
			<div className="bottom-items-home-container">
				{isPortrait ? (
					<p className="cosas-con-amor">cosas con amor</p>
				) : (
					<p className="cosas-con-amor">cosas hechas con mucho amor</p>
				)}
				<button
					onClick={() =>
						window.open("https://www.linkedin.com/in/franmartinezclavijo", "_blank")
					}
					rel="noopener noreferrer">
					LINKEDIN
				</button>
				<button
					onClick={() => window.open("https://github.com/Frankenweenee", "_blank")}
					rel="noopener noreferrer">
					GITHUB
				</button>
			</div>
		</div>
	);
}
