import { useEffect, useState } from "react";
import "./works.style.scss";
import { blogUrl } from "./fetch";

export default function Works() {
	const [posts, setPosts] = useState([]);
	const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
	useEffect(() => {
		setIsPortrait(window.innerHeight > window.innerWidth);
		const fetchData = async () => {
			try {
				const response = await fetch(`${blogUrl}/wp-json/wp/v2/posts?categories=3`);
				const data = await response.json();
				const postWithImages = await Promise.all(
					data.map(async (post) => {
						if (post.acf && post.acf.background_image) {
							const imageUrlResponse = await fetch(
								`${blogUrl}/wp-json/wp/v2/media/${post.acf.background_image}`
							);
							const imageData = await imageUrlResponse.json();
							const imageUrl = imageData.source_url;
							return { ...post, imageUrl };
						}
						return post;
					})
				);
				setPosts(postWithImages);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};
		fetchData();

		const handleResize = () => {
			setIsPortrait(window.innerHeight > window.innerWidth);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	const title = "WORKS";
	const Works = () => {
		return (
			<>
				{posts.map((post, i) => (
					<div
						onClick={() => window.open(post.link)}
						className="post-card"
						key={post.id}>
						<div id={`work_${i + 1}`} className="works-full-line">
							<p className="post-title">{post.title.rendered}</p>
							{post.imageUrl && <img src={post.imageUrl} alt="" />}
						</div>
					</div>
				))}
			</>
		);
	};

	return (
		<div className="works-main-container">
			{!isPortrait && <div id="works-up">{/* <Works /> */}</div>}
			<div id="big-works-up">
				<Works />
			</div>
			<div
				className="works-area-title"
				style={{
					bottom: "-6vh",
					left: "-0.2vw",
					fontSize: "25vh",
				}}>
				{title}
			</div>
			<div
				className="works-area-title"
				style={{
					width: "100%",
					justifyContent: "end",
					paddingRight: "5vw",
					bottom: "-45vh",
					fontSize: "40vh",
				}}>
				{title}
			</div>
		</div>
	);
}
