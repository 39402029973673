import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BlogEntrie from "./single-blog/blogEntrie";
import Home_react from "./home/web_page";

export default function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Home_react/>} />
				<Route path="/:slug" element={<BlogEntrie/>} />
			</Routes>
		</Router>
	);
}
